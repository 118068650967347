/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import '@ionic/angular/css/palettes/dark.class.css';

.user-menu-popover, .path-list-popover  {
  --width: fit-content;
  --max-width: 80vw;
}

ion-item {
  --transition: none;
}

.ios b {
  font-weight: 1000;
}

pm-label .label, pm-label .label * {
  font-size: 1.25rem;
}

pm-bullet pm-label .label,pm-bullet pm-label .label * {
  font-size: 1rem;
}

pm-label .label p:first-child {
  margin-block-end: 0;
  margin-top: 0;
}

pm-label .label p:last-child {
  margin-bottom: 0;
  margin-block-end: 0;
}
